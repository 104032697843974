export default function navigationMoreInit() {
  const navMoreRoot = document.querySelectorAll(
    '.js-navMore:not([data-jsinit])',
  );

  if (!navMoreRoot.length || typeof ResizeObserver !== 'function') {
    return;
  }

  [...navMoreRoot].forEach((root) => {
    const navMoreRootEl = root;
    if (root.dataset.jsinit) {
      return;
    }
    navMoreRootEl.dataset.jsinit = true;

    const navigationMore = () => {
      // we have second level UL, so we need just first level UL
      const rootList = root.querySelector('.js-navMore__root');
      const moreListParent = rootList.querySelector('.more');

      if (!moreListParent) {
        return;
      }

      const moreList = moreListParent.querySelector('ul');

      // Return element width
      const outerWidth = (el) => {
        let width = el.offsetWidth;
        const style = getComputedStyle(el);

        width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
        return width;
      };

      const moreMenuRebuild = () => {
        const getSpaceUsed = (items) => items.reduce((total, el) => total + outerWidth(el), 0);

        const getSpaceAvailable = (el) => {
          const minusWidth = root.dataset.minusavailabelwidth || 60;
          return outerWidth(el) - minusWidth;
        };

        const rootItems = root.querySelectorAll('.js-navMore__root > li');
        const rootItemsWidth = getSpaceUsed([...rootItems]);
        const availableSpace = getSpaceAvailable(navMoreRootEl);

        // If menu width is larger then available space, move last menu item to More list
        if (rootItemsWidth > availableSpace) {
          // Reselect second last main menu item
          const rootListLastChild = rootItems[rootItems.length - 2];

          if (rootListLastChild) {
            // Set its width on data attribute
            rootListLastChild.setAttribute(
              'data-width',
              outerWidth(rootListLastChild),
            );

            // Move last menu item to more list
            moreList.insertBefore(rootListLastChild, moreList.firstChild);

            // Repeat
            moreMenuRebuild();
          }
        } else {
          // Move more list first item to root menu if we have enough space
          const moreListFirstChild = moreListParent.querySelectorAll('li')[0];
          if (
            moreListFirstChild
            && rootItemsWidth + parseInt(moreListFirstChild.dataset.width, 10) < availableSpace
          ) {
            rootList.insertBefore(moreListFirstChild, moreListParent);
          }
        }
        // If we have More list, unhide More menu item
        if (moreListParent.querySelectorAll('li').length > 0) {
          moreListParent.classList.remove('hidden');
        } else {
          moreListParent.classList.add('hidden');
        }
      };

      const liveNowResize = () => {
        if (
          outerWidth(document.getElementById('container')) > outerWidth(root)
        ) {
          return;
        }
        moreMenuRebuild();
      };
      const defaultResize = () => {
        moreMenuRebuild();
      };
      const onResize = root.classList.contains('liveNowTop')
        ? liveNowResize
        : defaultResize;

      onResize();
      const resize = new ResizeObserver(onResize);
      resize.observe(root);
    };

    const openDropdowns = () => {
      const showDropdownLink = root.querySelectorAll('.showDropdown');
      if (!showDropdownLink.length) {
        return;
      }
      [...showDropdownLink].forEach((item) => {
        item.addEventListener('click', function dropdownCLick() {
          this.parentNode.classList.toggle('on');
        });
      });
    };

    const hideDropdowns = () => {
      const moreLink = root.querySelectorAll('.showDropdown');
      document.addEventListener('click', (event) => {
        const isClickInside = root.contains(event.target);
        if (!isClickInside) {
          [...moreLink].forEach((e) => e.parentNode.classList.remove('on'));
        }
      });
    };
    // Overflow was added to avoid CLS on page load
    navMoreRootEl.style.overflow = null;

    const enableMoreDropdown = (
      navMoreRootEl.classList.contains('js-navMoreDesktop')
        && matchMedia('screen and (min-width: 769px)').matches
    )
      || !navMoreRootEl.classList.contains('js-navMoreDesktop');

    if (enableMoreDropdown) {
      navigationMore();
      hideDropdowns();
      openDropdowns();
    }
  });
}
